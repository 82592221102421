import { decode } from 'jsonwebtoken'

export default class AuthService {
    constructor() {
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }
    login(username, password) {
        // Get a token from api server using the fetch api
        return this.fetch(`/api/account`, {
            method: 'POST',
            body: JSON.stringify({
                xemail: username,
                xpassword: password
            })
        }).then(res => {
            if (res.message === 'success') {
                const { token, reftoken } = res
                this.setToken(token) // Setting the token in localStorage}
                this.setRefToken(reftoken)
                return Promise.resolve(res)
            } else {
                this.logout()

            }
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        const reftoken = localStorage.getItem('reftoken')
        return !!token && !!reftoken //&& !this.isTokenExpired(token)  // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(token) {
        // Saves user token to localStorage
        localStorage.setItem('token', token)
    }
    setRefToken(token) {
        // Saves user token to localStorage
        localStorage.setItem('reftoken', token)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('token')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('reftoken')
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'token' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            window.location.reload(true)
            // var error = new Error(response.statusText)
            // error.response = response
            // throw error
        }
    }
}
