import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}
axios.interceptors.request.use(function (config) {
    config.headers.authorization = localStorage.getItem('token')
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('reftoken')
        window.location.reload(true)

    }
    const originalRequest = error.config;

    if (error.response.status === 498 && !originalRequest._retry) {

        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject })
            }).then(token => {
                originalRequest.headers['authorization'] = token;
                return axios(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const reftoken = window.localStorage.getItem('reftoken');
        return new Promise(function (resolve, reject) {

            axios.post('/api/account/refresh', { reftoken })
                .then(({ data }) => {
                    window.localStorage.setItem('token', data.token);
                    window.localStorage.setItem('reftoken', data.reftoken);
                    axios.defaults.headers.common['authorization'] = data.token;
                    originalRequest.headers['authorization'] = data.token;
                    processQueue(null, data.token);
                    resolve(axios(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .then(() => { isRefreshing = false })
        })
    }

    return Promise.reject(error);
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
